import { handleRequest } from "../helpers/handleRequest";

export const getTeams = async () => {
//     const data = [
//     {
//         "id": 1
//         ,"captain1" : "Andy"
//         ,"captain2" : "Alivia"
//         ,"members" : "Joslynn Worley, Evan Cooper, Payton Rethorst, Jay Parker, Eliana Hagemann, Mason Schwarting, Dylan Wall, Rachel Covert, Kim Gomez, Olivia McArthur"
//         ,"score" : "12"
//     },
//     {
//         "id": 2
//         ,"captain1" : "Elizabeth"
//         ,"captain" : "Katie"
//         ,"members" : "Ike Smith, Freddy Harbols, Josie Steele, Max Haffener, Connor Stewart, Jackson Haffener, Savannah, Nathan Hoss"
//         ,"score" : "50" 
//     }
// ];

//     return data;
return handleRequest("GET", "/Team")
        .then((data) => {
            return data;
        })

}