export const allComponentsInfo = [
    //It goes like this:
    //[nameOfComponent, nameOfComponentAsItsUsedInURLRequestsl, photoExample]
    ["Picture Showcase", "PictureShowcase", ""],
    ["Committees", "Committee", ""],
    ["Membership Info", "MembershipInfo", ""],
    ["Milestones", "Milestone", ""],
    ["Recruitment Activities", "RecruitmentActivity", ""],
    ["Teams", "Team", ""],
    ["Awards", "Award", ""],
    ["Chapter Activities", "ChapterActivity", ""],
    ["Contact Information", "ContactInfo", ""],
    ["Officers", "Officer", ""],
    ["Program of Work", "ProgramOfWork", ""],
    ["Sources", "Source", ""],
    ["Supporters", "Supporter", ""],
    ["FCCLA Info", "FCCLAInfo", ""],
    
]