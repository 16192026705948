import { handleRequest } from "../../helpers/handleRequest";

export const getHomeImages = async () => {
//     const ImageData = [
//     {
//         "id": 1,
//         "image" : "https://media.istockphoto.com/id/1336021035/photo/black-and-white-color-cat-looking-at-camera-curiosity.jpg?b=1&s=170667a&w=0&k=20&c=_z9Uj-G_QzE-mxqd4-mSRFYFTwB3UEi6g_hLySo_s2Y="
//     },
//     {
//         "id": 2,
//         "image" : "https://media.istockphoto.com/id/1366990539/photo/a-cute-domestic-tabby-kitten-with-a-white-chest-looks-sadly-at-the-camera.jpg?b=1&s=170667a&w=0&k=20&c=PHLznYfsbNcIV-jCbN6pRyvoxB3-SF2Sju3SItkhn1o="
//     },
//     {
//         "id" : 3,
//         "image" : "https://media.istockphoto.com/id/1298824982/photo/a-happy-long-haired-brown-tabby-cat-is-relaxing-on-a-felt-cat-bed-at-home-holding-his-paws.jpg?b=1&s=170667a&w=0&k=20&c=ZnJdiwVoL9TqBMXN2cKugS6RiDaUrpKG4yMyTwkWWFk="

//     },
//     {
//         "id" : 4,
//         "image" : "https://images.unsplash.com/photo-1543852786-1cf6624b9987?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2F0c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"

//     },
//     {
//         "id" : 5,
//         "image" : "https://images.unsplash.com/photo-1583795128727-6ec3642408f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Y2F0c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
//     }
// ];

//     return ImageData;

return handleRequest("GET", "/PictureShowcase")
        .then((data) => {
            return data;
        })


}