import { handleRequest } from "../helpers/handleRequest";

export const getOfficers = async () => {
//     const data = [
//     {
//         "id": 1,
//         "image" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNOR5KFiOGzzVOu7HR9nhCY5TnjD6zY3edmJ7_kV_scesu6EgGvMcjS-IL_gpeycDcc2w&usqp=CAU",
//         "name" : "Olivia Olson",        
//         "title" : "Co-president",
//         "goals" : "Insert-promice-here",
//         "startTime" : "2022-01-01T23:59:00.000+00:00",
//         "endTime" : "2023-01-01T23:59:00.000+00:00",
//         "email" : "aleciprian@kearneycats.com"
//     },
//     {
//         "id": 2,
//         "image" : "https://twistedsifter.com/wp-content/uploads/2021/05/present-day-abraham-lincoln.jpg",
//         "name" : "Alex Ciprian",        
//         "title" : "Best Member",
//         "goals" : "Will make a website, then never participate agian",
//         "startTime" : "2022-06-01T23:59:00.000+00:00",
//         "endTime" : "2023-01-01T23:59:00.000+00:00",
//         "email" : "aleciprian@kearneycats.com"
//     }
// ];

//     return data;

    return handleRequest("GET", "/Officer")
    .then((data) => {
        return data;
    })

}